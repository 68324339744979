<template>
  <div class="g_page_box qrcode">
    获取微信code: {{code}}
  </div>
</template>
<script>
import {get_url_params} from '../../utils/format'

export default {
  data () {
    return {
      code: null
    }
  },
  computed: {
  },
  components: {
  },
  watch: {
  },
  created () {
    // 控制退出公众号
    window.history.pushState(null, null)
    window.addEventListener('popstate', this.popstate, false)

    this.init_data()
  },
  destroyed () {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    popstate (e) {
      //qiankun 微服务内部api，更新可能引起未知bug
      if(e.singleSpaTrigger === "pushState" || e.singleSpaTrigger === "replaceState"){
        return
      }
      wx.closeWindow() // 微信网页退出
    },
    async init_data () {
      let {code} = get_url_params(location.href.split('#')[0])

      if (!code) {
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${GLOBAL_CONFIG.app_id}&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        location.href = url;
        throw '暂停执行'; // 退出后面的执行
      }

      this.code = code

    }
  }
}
</script>
<style scoped>
</style>
